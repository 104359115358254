<template>
    <div class="card card-custom example example-compact">
        <div class="card-header">
            <h3 class="card-title">{{title}}</h3>
            <button type="button" class="close" @click="cancelForm()">
                <i aria-hidden="true" class="ki ki-close"></i>
            </button>
        </div>

        <!--begin::Form-->
        <form class="form fv-plugins-bootstrap fv-plugins-framework" ref="dataForm" id="dataForm">
            <div class="card-body" style="max-height:500px;overflow:auto;">
                <div class="form-group row">
                    <label class="col-lg-3 col-form-label">接口名称 <span class="text-danger">*</span>:</label>
                    <div class="col-lg-6">
                        <input type="text" class="form-control" placeholder="请输入接口名称" v-model="data_form.api_name" name="api_name">
                        <span class="form-text text-muted"></span>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-lg-3 col-form-label">接口代码 <span class="text-danger">*</span>:</label>
                    <div class="col-lg-6">
                        <input type="text" class="form-control" placeholder="请输入接口代码" v-model="data_form.api_code" name="api_code">
                        <span class="form-text text-muted"></span>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-lg-3 col-form-label">接口地址:</label>
                    <div class="col-lg-6">
                        <input type="text" class="form-control" placeholder="请输入接口地址" v-model="data_form.api_url" name="api_url">
                        <span class="form-text text-muted"></span>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-lg-3 col-form-label">说明:</label>
                    <div class="col-lg-6">
                        <textarea class="form-control" placeholder="请输入接口说明" v-model="data_form.note" name="note"></textarea>
                        <span class="form-text text-muted"></span>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-lg-6">
                        <a href="javascript:;" class="btn btn-primary font-weight-bolder" @click="addDetail()">
                            添加接口属性
                        </a>
                    </div>
                </div>
                <div class="form-group table-responsive">
                    <table class="table ">
                        <thead>
                            <tr class="alert alert-light">
                                <th scope="col">键</th>
                                <th scope="col">值</th>
                                <th scope="col" width="100px">操作</th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="(item, idx) in data_form.details">
                                <tr :key="idx">
                                    <td>
                                        <input type="text" class="form-control" v-model="data_form.details[idx].attr_key" name="attr_key">
                                    </td>
                                    <td>
                                        <input type="text" class="form-control" v-model="data_form.details[idx].attr_name" name="attr_name">
                                    </td>
                                    <td>
                                        <a href="javascript:;" class="btn btn-sm btn-clean btn-icon mr-2" title="删除" @click="deleteDetail(idx)">
                                            <i class="la la-trash-o icon-lg"></i>
                                        </a>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="card-footer">
                <button ref="data_form_submit" class="btn btn-primary mr-2">保存</button>
                <button class="btn btn-secondary" type="reset" @click="cancelForm()">关闭</button>
            </div>
        </form>
        <!--end::Form-->
    </div>
</template>
<script>

    import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
    import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
    import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
    import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
    import KTUtil from "@/assets/js/components/util";

    import {addApiSetting} from '@/api/apisetting';

    export default {
        name: "api_setting_add",
        props: {
            title: String,
            dialog_data: Object
        },
        created:function() {

        },
        components :{

        },
        data() {
            return {
                data_form:{
                    api_name: '',
                    api_url: '',
                    api_code: '',
                    note: '',
                    details: []
                },
            }
        },
        mounted() {
            const data_form = KTUtil.getById("dataForm");

            //表单数据验证
            this.fv = formValidation(data_form, {
                fields: {
                    api_name: {
                        validators: {
                            notEmpty: {
                                message: "接口名称不能为空"
                            }
                        }
                    },
                    api_code: {
                        validators: {
                            notEmpty: {
                                message: "接口代码不能为空"
                            }
                        }
                    }
                },
                plugins: {
                    trigger: new Trigger(),
                    submitButton: new SubmitButton(),
                    bootstrap: new Bootstrap()
                }
            });
            this.fv.on("core.form.valid", () => {
                var _this = this;

                //按钮加载动画
                const submitButton = this.$refs["data_form_submit"];
                submitButton.classList.add("spinner", "spinner-light", "spinner-right");

                addApiSetting(_this.data_form).then(function (res){
                    if (res) {
                        KTUtil.toast(_this, '结果提醒', '保存成功', 'success');
                        data_form.reset();
                        _this.$emit('opeareResultHandler', 1);
                    }
                    submitButton.classList.remove(
                        "spinner",
                        "spinner-light",
                        "spinner-right"
                    );
                });
            });
        },
        methods: {
            cancelForm(){
                this.$emit('opeareResultHandler', 0);

            },
            regionChange (data) {
                this.data_form.struct_address = data;
            },
            addDetail() {
                this.data_form.details.push({'attr_key':'', 'attr_name':''});
            },
            deleteDetail(idx) {
                this.data_form.details.splice(idx, 1);
            }
        }
    }
</script>
