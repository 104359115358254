import request from './request';

export function getApiSettingList(params){
    return request({
        url: 'apiSetting/getApiSettingList',
        method: 'post',
        data: params
    });
}

export function addApiSetting(params){
    return request({
        url: 'apiSetting/addApiSetting',
        method: 'post',
        data: params
    });
}

export function updateApiSetting(params){
    return request({
        url: 'apiSetting/updateApiSetting',
        method: 'post',
        data: params
    });
}


export function deleteSetting(params){
    return request({
        url: 'apiSetting/deleteSetting',
        method: 'post',
        data: params
    });
}
